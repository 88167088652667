import { Locale } from '@artegeie/page-constants/dist/i18n';
export type Route = {
    code: Codes;
    slug: Slugs;
};

type Routes = Record<Locale, Route[]>;

export enum Codes {
    'ACCESSIBLE_PROGRAMS' = 'ACCESSIBLE_PROGRAMS',
    'ARTE_CONCERT' = 'ARTE_CONCERT',
    'AUDIO_DESCRIPTION' = 'AUDIO_DESCRIPTION',
    'AVN' = 'AVN',
    'CATEGORY_ACT' = 'CATEGORY_ACT',
    'CATEGORY_CIN' = 'CATEGORY_CIN',
    'CATEGORY_CPO' = 'CATEGORY_CPO',
    'CATEGORY_DEC' = 'CATEGORY_DEC',
    'CATEGORY_HIS' = 'CATEGORY_HIS',
    'CATEGORY_SCI' = 'CATEGORY_SCI',
    'CATEGORY_SER' = 'CATEGORY_SER',
    'CLOSED_CAPTIONING' = 'CLOSED_CAPTIONING',
    'CLUB_HOME' = 'CLUB_HOME',
    'COLLECTION' = 'COLLECTION',
    'COLLECTORS' = 'COLLECTORS',
    'CONCERT_GUIDE' = 'CONCERT_GUIDE',
    'DOR' = 'DOR',
    'EDITORIAL_LAST_CHANCE' = 'EDITORIAL_LAST_CHANCE',
    'EMI' = 'EMI',
    'FESTIVAL_CANNES' = 'FESTIVAL_CANNES',
    'HES' = 'HES',
    'LAST_CHANCE' = 'LAST_CHANCE',
    'LIVE' = 'LIVE',
    'MAGAZINES' = 'MAGAZINES',
    'MOST_RECENT' = 'MOST_RECENT',
    'MOST_VIEWED' = 'MOST_VIEWED',
    'MY_ADVANTAGE' = 'MY_ADVANTAGE',
    'PRODNUM' = 'PRODNUM',
    'SEARCH_HOME' = 'SEARCH_HOME',
    'SER_NORTH' = 'SER_NORTH',
    'SUBCATEGORY_ACC' = 'SUBCATEGORY_ACC',
    'SUBCATEGORY_ADS' = 'SUBCATEGORY_ADS',
    'SUBCATEGORY_AJO' = 'SUBCATEGORY_AJO',
    'SUBCATEGORY_ART' = 'SUBCATEGORY_ART',
    'SUBCATEGORY_ATA' = 'SUBCATEGORY_ATA',
    'SUBCATEGORY_AUV' = 'SUBCATEGORY_AUV',
    'SUBCATEGORY_BAR' = 'SUBCATEGORY_BAR',
    'SUBCATEGORY_CHU' = 'SUBCATEGORY_CHU',
    'SUBCATEGORY_CIV' = 'SUBCATEGORY_CIV',
    'SUBCATEGORY_CLA' = 'SUBCATEGORY_CLA',
    'SUBCATEGORY_CMG' = 'SUBCATEGORY_CMG',
    'SUBCATEGORY_CMU' = 'SUBCATEGORY_CMU',
    'SUBCATEGORY_DCY' = 'SUBCATEGORY_DCY',
    'SUBCATEGORY_ENB' = 'SUBCATEGORY_ENB',
    'SUBCATEGORY_ENN' = 'SUBCATEGORY_ENN',
    'SUBCATEGORY_ENQ' = 'SUBCATEGORY_ENQ',
    'SUBCATEGORY_EVA' = 'SUBCATEGORY_EVA',
    'SUBCATEGORY_FIC' = 'SUBCATEGORY_FIC',
    'SUBCATEGORY_FLM' = 'SUBCATEGORY_FLM',
    'SUBCATEGORY_HIP' = 'SUBCATEGORY_HIP',
    'SUBCATEGORY_IDE' = 'SUBCATEGORY_IDE',
    'SUBCATEGORY_JAZ' = 'SUBCATEGORY_JAZ',
    'SUBCATEGORY_JUN' = 'SUBCATEGORY_JUN',
    'SUBCATEGORY_KUL' = 'SUBCATEGORY_KUL',
    'SUBCATEGORY_LGP' = 'SUBCATEGORY_LGP',
    'SUBCATEGORY_MCL' = 'SUBCATEGORY_MCL',
    'SUBCATEGORY_MET' = 'SUBCATEGORY_MET',
    'SUBCATEGORY_MUA' = 'SUBCATEGORY_MUA',
    'SUBCATEGORY_MUD' = 'SUBCATEGORY_MUD',
    'SUBCATEGORY_MUE' = 'SUBCATEGORY_MUE',
    'SUBCATEGORY_NEA' = 'SUBCATEGORY_NEA',
    'SUBCATEGORY_OPE' = 'SUBCATEGORY_OPE',
    'SUBCATEGORY_POP' = 'SUBCATEGORY_POP',
    'SUBCATEGORY_SAN' = 'SUBCATEGORY_SAN',
    'SUBCATEGORY_SES' = 'SUBCATEGORY_SES',
    'SUBCATEGORY_TEC' = 'SUBCATEGORY_TEC',
    'SUBCATEGORY_VIA' = 'SUBCATEGORY_VIA',
    'SUBCATEGORY_WEB' = 'SUBCATEGORY_WEB',
    'SUBCATEGORY_XXE' = 'SUBCATEGORY_XXE',
    'TV_GUIDE' = 'TV_GUIDE',
}

export enum Slugs {
    '20TH-CENTURY' = '20th-century',
    '30-JAHRE' = '30-jahre',
    'A-LA-MESA' = 'a-la-mesa',
    'A-LA-UNE' = 'a-la-une',
    'A-TABLE' = 'a-table',
    'A-VENIR' = 'a-venir',
    'ACTUALITE-DU-CINEMA' = 'actualite-du-cinema',
    'ACTUALITES-CULTURELLES' = 'actualites-culturelles',
    'AGENDA' = 'agenda',
    'AKTUELLES' = 'aktuelles',
    'AKTUELLES-UND-GESELLSCHAFT' = 'aktuelles-und-gesellschaft',
    'AL-GRANO' = 'al-grano',
    'AMBIENTE-E-NATURA' = 'ambiente-e-natura',
    'ANALISI' = 'analisi',
    'ANALISIS' = 'analisis',
    'ANALIZY' = 'analizy',
    'ARTE' = 'arte',
    'ARTE-CONCERT' = 'arte-concert',
    'ARTE-CONCERT_ARTE_CONCERT' = 'arte-concert_ARTE_CONCERT',
    'ARTE-JOURNAL' = 'arte-journal',
    'ARTES' = 'artes',
    'ARTES-ESCENICAS' = 'artes-escenicas',
    'ARTI-DELLO-SPETTACOLO' = 'arti-dello-spettacolo',
    'ARTS' = 'arts',
    'ARTS-DE-LA-SCENE' = 'arts-de-la-scene',
    'ATTUALITA-CULTURALE' = 'attualita-culturale',
    'AUTREMENT-VU' = 'autrement-vu',
    'BALD-OFFLINE' = 'bald-offline',
    'BAROCKMUSIK' = 'barockmusik',
    'BAROQUE' = 'baroque',
    'BARRIEREFREIE-PROGRAMME' = 'barrierefreie-programme',
    'BIOGRAPHIEN' = 'biographien',
    'BUEHNEN-PERFORMANCE' = 'buehnen-performance',
    'BUSQUEDA-HOME' = 'busqueda-home',
    'CIENCIAS' = 'ciencias',
    'CINE' = 'cine',
    'CINEMA' = 'cinema',
    'CIVILISATIONS' = 'civilisations',
    'CIVILIZACIONES' = 'civilizaciones',
    'CIVILTA' = 'civilta',
    'CLASICA' = 'clasica',
    'CLASSICAL' = 'classical',
    'CLASSIQUE' = 'classique',
    'CLUB-HOME' = 'club-home',
    'COLLECTION-PAGE-COLLECTIONID_RC-016485' = 'collection-page-collectionid_RC-016485',
    'COMING-SOON-ON-ARTETV' = 'coming-soon-on-artetv',
    'CON-OTROS-OJOS' = 'con-otros-ojos',
    'CONCERT-KONZERTE-LIVE-UND-IM-REPLAY' = 'concert-konzerte-live-und-im-replay',
    'CONCERT-PLUS-DE-600-CONCERTS-EN-STREAMING-ET-EN-ACCES-LIBRE' = 'concert-plus-de-600-concerts-en-streaming-et-en-acces-libre',
    'COURTS-HUMORISTIQUES' = 'courts-humoristiques',
    'COURTS-METRAGES' = 'courts-metrages',
    'CULTURA' = 'cultura',
    'CULTURA-POP' = 'cultura-pop',
    'CULTURA-Y-POP' = 'cultura-y-pop',
    'CULTURE' = 'culture',
    'CULTURE-ET-POP' = 'culture-et-pop',
    'CULTURE-POP' = 'culture-pop',
    'CYWILIZACJE' = 'cywilizacje',
    'DAS-20-JAHRHUNDERT' = 'das-20-jahrhundert',
    'DECRYPTAGES' = 'decryptages',
    'DEMNACHST' = 'demnachst',
    'DEMNAECHST' = 'demnaechst',
    'DERNIERE-CHANCE' = 'derniere-chance',
    'DIE-ZEIT-VOR-DEM-20-JAHRHUNDERT' = 'die-zeit-vor-dem-20-jahrhundert',
    'DIGITAL-PRODUCTIONS' = 'digital-productions',
    'DIGITALE-PRODUKTIONEN' = 'digitale-produktionen',
    'DISCOVERY' = 'discovery',
    'DLA-MLODZIEZY' = 'dla-mlodziezy',
    'DOCUMENTAIRES-ET-REPORTAGES' = 'documentaires-et-reportages',
    'DOCUMENTALES-Y-REPORTAJES' = 'documentales-y-reportajes',
    'DOCUMENTARI-E-REPORTAGE' = 'documentari-e-reportage',
    'DOCUMENTARIES-AND-REPORTAGE' = 'documentaries-and-reportage',
    'DOKUMENTATIONEN-UND-REPORTAGEN' = 'dokumentationen-und-reportagen',
    'DOKUMENTY-I-REPORTAZE' = 'dokumenty-i-reportaze',
    'EL-SIGLO-XX' = 'el-siglo-xx',
    'ELECTRONIC' = 'electronic',
    'ELETTRONICA' = 'elettronica',
    'EMISIONES' = 'emisiones',
    'EMISSIONS' = 'emissions',
    'EN-BREF' = 'en-bref',
    'ENQUETES-ET-REPORTAGES' = 'enquetes-et-reportages',
    'ENTDECKUNG-DER-WELT' = 'entdeckung-der-welt',
    'ENVIRONMENT' = 'environment',
    'ENVIRONNEMENT-ET-NATURE' = 'environnement-et-nature',
    'EVASION' = 'evasion',
    'FAMOUS-HISTORICAL-FIGURES' = 'famous-historical-figures',
    'FERNSEHFILME' = 'fernsehfilme',
    'FERNSEHFILME-UND-SERIEN' = 'fernsehfilme-und-serien',
    'FICTIONS' = 'fictions',
    'FILME' = 'filme',
    'FILMFESTIVAL-CANNES' = 'filmfestival-cannes',
    'FILMGROESSEN' = 'filmgroessen',
    'FILMS' = 'films',
    'FILMS-MUETS' = 'films-muets',
    'FOOD' = 'food',
    'GESCHICHTE' = 'geschichte',
    'GESUNDHEIT-UND-MEDIZIN' = 'gesundheit-und-medizin',
    'GRANDES-PERSONAJES' = 'grandes-personajes',
    'GUIDE' = 'guide',
    'HELDINNEN-IN-SERIE' = 'heldinnen-in-serie',
    'HEROINES-EN-SERIE' = 'heroines-en-serie',
    'HINTERGRUND' = 'hintergrund',
    'HIP-HOP' = 'hip-hop',
    'HISTOIRE' = 'histoire',
    'HISTORIA' = 'historia',
    'HISTORY' = 'history',
    'I-GRANDI-PERSONAGGI' = 'i-grandi-personaggi',
    'IDEAS' = 'ideas',
    'IDEE' = 'idee',
    'IDEENWELTEN' = 'ideenwelten',
    'IDEES' = 'idees',
    'IN-BRIEF' = 'in-brief',
    'IN-PILLOLE' = 'in-pillole',
    'INCHIESTE-E-REPORTAGE' = 'inchieste-e-reportage',
    'INFO-ET-SOCIETE' = 'info-et-societe',
    'INSIGHTS' = 'insights',
    'INVESTIGACIONES-Y-REPORTAJES' = 'investigaciones-y-reportajes',
    'INVESTIGATION-AND-REPORTS' = 'investigation-and-reports',
    'JAK-ZYJA-INNI' = 'jak-zyja-inni',
    'JAZZ' = 'jazz',
    'JOVEN' = 'joven',
    'JUNIOR' = 'junior',
    'KIDS' = 'kids',
    'KINO' = 'kino',
    'KLASSIK' = 'klassik',
    'KORZYSCI' = 'korzysci',
    'KULINARIK' = 'kulinarik',
    'KULTUR-NEWS' = 'kultur-news',
    'KULTUR-UND-POP' = 'kultur-und-pop',
    'KULTURA' = 'kultura',
    'KUNST' = 'kunst',
    'KURZ-UND-WITZIG' = 'kurz-und-witzig',
    'KURZFILME' = 'kurzfilme',
    'LAST-CHANCE' = 'last-chance',
    'LEBEN-ANDERSWO' = 'leben-anderswo',
    'LES-DERNIERES-CHANCES' = 'les-dernieres-chances',
    'LES-GRANDS-DU-7E-ART' = 'les-grands-du-7e-art',
    'LES-GRANDS-PERSONNAGES' = 'les-grands-personnages',
    'LETZTE-CHANCE' = 'letzte-chance',
    'LIVE-TV-DIE-PROGRAMME-VON-ARTE-IM-LIVESTREAM' = 'live-tv-die-programme-von-arte-im-livestream',
    'LIVES-FROM-AFAR' = 'lives-from-afar',
    'LOS-MAS-RECIENTES' = 'los-mas-recientes',
    'LOS-OJOS-DEL-ARTE' = 'los-ojos-del-arte',
    'MAGAZINE-SHOWS' = 'magazine-shows',
    'MAGAZYNY' = 'magazyny',
    'MAS-VISTOS' = 'mas-vistos',
    'MEDECINE-ET-SANTE' = 'medecine-et-sante',
    'MEDICINA-E-SALUTE' = 'medicina-e-salute',
    'MEDICINA-Y-SALUD' = 'medicina-y-salud',
    'MEDICINE-AND-HEALTH' = 'medicine-and-health',
    'MEDIO-AMBIENTE-Y-NATURALEZA' = 'medio-ambiente-y-naturaleza',
    'MEDYCYNA-I-ZDROWIE' = 'medycyna-i-zdrowie',
    'MEISTGESEHEN' = 'meistgesehen',
    'MES-AVANTAGES' = 'mes-avantages',
    'METAL' = 'metal',
    'MIS-VENTAJAS' = 'mis-ventajas',
    'MOST-RECENT' = 'most-recent',
    'MOST-VIEWED' = 'most-viewed',
    'MUSICA-BAROCCA' = 'musica-barocca',
    'MUSICA-BARROCA' = 'musica-barroca',
    'MUSICA-CLASSICA' = 'musica-classica',
    'MUSICA-ELECTRONICA' = 'musica-electronica',
    'MUSICAS-DEL-MUNDO' = 'musicas-del-mundo',
    'MUSICHE-DEL-MONDO' = 'musiche-del-mondo',
    'MUSIQUE-BAROQUE' = 'musique-baroque',
    'MUSIQUES-ELECTRONIQUES' = 'musiques-electroniques',
    'MUZYKA-BAROKOWA' = 'muzyka-barokowa',
    'MUZYKA-ELEKTRONICZNA' = 'muzyka-elektroniczna',
    'MUZYKA-KLASYCZNA' = 'muzyka-klasyczna',
    'MUZYKA-Z-CALEGO-SWIATA' = 'muzyka-z-calego-swiata',
    'MY-BENEFITS' = 'my-benefits',
    'NAJCZESCIEJ-OGLADANE' = 'najczesciej-ogladane',
    'NAJNOWSZE' = 'najnowsze',
    'NATUR-UND-TIERE' = 'natur-und-tiere',
    'NATURA-E-ANIMALI' = 'natura-e-animali',
    'NATURA-I-SRODOWISKO' = 'natura-i-srodowisko',
    'NATURALEZA-Y-ANIMALES' = 'naturaleza-y-animales',
    'NATURE-ET-ANIMAUX' = 'nature-et-animaux',
    'NAUKA' = 'nauka',
    'NEUESTE-VIDEOS' = 'neueste-videos',
    'OCZAMI-ARTYSTOW' = 'oczami-artystow',
    'ODKRYCIA' = 'odkrycia',
    'OPER' = 'oper',
    'OPERA' = 'opera',
    'OSTATNIA-SZANSA' = 'ostatnia-szansa',
    'OTROS-LUGARES-OTRAS-VIDAS' = 'otros-lugares-otras-vidas',
    'PERFORMING-ARTS' = 'performing-arts',
    'PERSPEKTIVWECHSEL' = 'perspektivwechsel',
    'PIU-RECENTI' = 'piu-recenti',
    'PIU-VISTI' = 'piu-visti',
    'PLANET-EARTH' = 'planet-earth',
    'PLUS-RECENTES' = 'plus-recentes',
    'PLUS-VUES' = 'plus-vues',
    'PODANO-DO-STOLU' = 'podano-do-stolu',
    'PODROZE' = 'podroze',
    'POLITICA-E-SOCIETA' = 'politica-e-societa',
    'POLITICA-Y-SOCIEDAD' = 'politica-y-sociedad',
    'POLITICS-AND-SOCIETY' = 'politics-and-society',
    'POLITYKA-I-SPOLECZENSTWO' = 'polityka-i-spoleczenstwo',
    'POP-CULTURE' = 'pop-culture',
    'POP-I-ROCK' = 'pop-i-rock',
    'POP-ROCK' = 'pop-rock',
    'POP-Y-ROCK' = 'pop-y-rock',
    'POPKULTUR' = 'popkultur',
    'POPKULTURA' = 'popkultura',
    'PRODUCCIONES-DIGITALES' = 'producciones-digitales',
    'PRODUCTIONS-NUMERIQUES' = 'productions-numeriques',
    'PRODUKCJE-CYFROWE' = 'produkcje-cyfrowe',
    'PRODUZIONI-DIGITALI' = 'produzioni-digitali',
    'PROGRAMME-MIT-AUDIODESKRIPTION' = 'programme-mit-audiodeskription',
    'PROGRAMME-MIT-UNTERTITELN' = 'programme-mit-untertiteln',
    'PROGRAMMES' = 'programmes',
    'PROGRAMMES-EN-AUDIODESCRIPTION' = 'programmes-en-audiodescription',
    'PROGRAMMES-EN-SOUS-TITRAGE-SOURD-ET-MALENTENDANT' = 'programmes-en-sous-titrage-sourd-et-malentendant',
    'PROGRAMMI' = 'programmi',
    'PROGRAMY' = 'programy',
    'PRONTO-DISPONIBLE' = 'pronto-disponible',
    'PROSSIMAMENTE' = 'prossimamente',
    'PRZYRODA-I-ZWIERZETA' = 'przyroda-i-zwierzeta',
    'PUNTI-DI-VISTA' = 'punti-di-vista',
    'RECHERCHE' = 'recherche',
    'REISEN' = 'reisen',
    'REPORTAGEN-UND-RECHERCHEN' = 'reportagen-und-recherchen',
    'RICERCA-HOME' = 'ricerca-home',
    'RUND-UM-DEN-FILM' = 'rund-um-den-film',
    'SCIENCES' = 'sciences',
    'SCIENZE' = 'scienze',
    'SEARCH-HOME' = 'search-home',
    'SENDUNGEN' = 'sendungen',
    'SERIALE-I-FILMY-FABULARNE' = 'seriale-i-filmy-fabularne',
    'SERIALE-SLEDCZE-I-REPORTAZE' = 'seriale-sledcze-i-reportaze',
    'SERIE-E-FICTION' = 'serie-e-fiction',
    'SERIEN' = 'serien',
    'SERIES' = 'series',
    'SERIES-ET-FICTIONS' = 'series-et-fictions',
    'SERIES-NORDIQUES' = 'series-nordiques',
    'SERIES-Y-FICCIONES' = 'series-y-ficciones',
    'SKANDINAVISCHE-SERIEN' = 'skandinavische-serien',
    'STORIA' = 'storia',
    'STUMMFILME' = 'stummfilme',
    'SUCHE' = 'suche',
    'SWITCHING-VIEWPOINTS' = 'switching-viewpoints',
    'SZTUKA' = 'sztuka',
    'TECHNIK-UND-INNOVATION' = 'technik-und-innovation',
    'TECHNOLOGIE-I-INNOWACJE' = 'technologie-i-innowacje',
    'TECHNOLOGIES-ET-INNOVATIONS' = 'technologies-et-innovations',
    'TECHNOLOGY-AND-INNOVATION' = 'technology-and-innovation',
    'TECNOLOGIA-E-INNOVAZIONE' = 'tecnologia-e-innovazione',
    'TECNOLOGIAS-E-INNOVACION' = 'tecnologias-e-innovacion',
    'THROUGH-THE-EYES-OF-ARTISTS' = 'through-the-eyes-of-artists',
    'TRAVEL' = 'travel',
    'TV-EN-DIRECT-REGARDER-ARTE-EN-DIRECT-SUR-INTERNET' = 'tv-en-direct-regarder-arte-en-direct-sur-internet',
    'ULTIMA-OPORTUNIDAD' = 'ultima-oportunidad',
    'ULTIMA-OPPORTUNITA' = 'ultima-opportunita',
    'UMWELT-UND-NATUR' = 'umwelt-und-natur',
    'VANTAGGI' = 'vantaggi',
    'VERSIONS-ACCESSIBLES-DES-PROGRAMMES' = 'versions-accessibles-des-programmes',
    'VIAGGI' = 'viaggi',
    'VIAGGI-E-SCOPERTE' = 'viaggi-e-scoperte',
    'VIAJES' = 'viajes',
    'VIAJES-Y-NATURALEZA' = 'viajes-y-naturaleza',
    'VIES-D-AILLEURS' = 'vies-d-ailleurs',
    'VITE-LONTANE' = 'vite-lontane',
    'VORTEILE' = 'vorteile',
    'VOYAGES-ET-DECOUVERTES' = 'voyages-et-decouvertes',
    'W-SKROCIE' = 'w-skrocie',
    'WEBSERIEN' = 'webserien',
    'WEBSERIES' = 'webseries',
    'WISSEN-KOMPAKT' = 'wissen-kompakt',
    'WISSENSCHAFT' = 'wissenschaft',
    'WKROTCE-DOSTEPNE' = 'wkrotce-dostepne',
    'WORLD' = 'world',
    'WYBITNE-POSTACIE' = 'wybitne-postacie',
    'WYSTEPY-SCENICZNE' = 'wystepy-sceniczne',
    'WYSZUKIWANIE-HOME' = 'wyszukiwanie-home',
    'XX-SECOLO' = 'xx-secolo',
    'XX-WIEK' = 'xx-wiek',
    'XXE-SIECLE' = 'xxe-siecle',
    'ZMIANA-PERSPEKTYWY' = 'zmiana-perspektywy',
}

export const routes: Routes = {
    en: [
        {
            code: Codes['ARTE_CONCERT'],
            slug: Slugs['ARTE-CONCERT'],
        },
        {
            code: Codes['AVN'],
            slug: Slugs['COMING-SOON-ON-ARTETV'],
        },
        {
            code: Codes['CATEGORY_ACT'],
            slug: Slugs['POLITICS-AND-SOCIETY'],
        },
        {
            code: Codes['CATEGORY_CIN'],
            slug: Slugs['CINEMA'],
        },
        {
            code: Codes['CATEGORY_CPO'],
            slug: Slugs['CULTURE'],
        },
        {
            code: Codes['CATEGORY_DEC'],
            slug: Slugs['DISCOVERY'],
        },
        {
            code: Codes['CATEGORY_HIS'],
            slug: Slugs['HISTORY'],
        },
        {
            code: Codes['CATEGORY_SCI'],
            slug: Slugs['SCIENCES'],
        },
        {
            code: Codes['CATEGORY_SER'],
            slug: Slugs['SERIES'],
        },
        {
            code: Codes['CLUB_HOME'],
            slug: Slugs['CLUB-HOME'],
        },
        {
            code: Codes['COLLECTION'],
            slug: Slugs['COLLECTION-PAGE-COLLECTIONID_RC-016485'],
        },
        {
            code: Codes['DOR'],
            slug: Slugs['DOCUMENTARIES-AND-REPORTAGE'],
        },
        {
            code: Codes['EMI'],
            slug: Slugs['MAGAZINE-SHOWS'],
        },
        {
            code: Codes['LAST_CHANCE'],
            slug: Slugs['LAST-CHANCE'],
        },
        {
            code: Codes['MAGAZINES'],
            slug: Slugs['PROGRAMMES'],
        },
        {
            code: Codes['MOST_RECENT'],
            slug: Slugs['MOST-RECENT'],
        },
        {
            code: Codes['MOST_VIEWED'],
            slug: Slugs['MOST-VIEWED'],
        },
        {
            code: Codes['MY_ADVANTAGE'],
            slug: Slugs['MY-BENEFITS'],
        },
        {
            code: Codes['PRODNUM'],
            slug: Slugs['DIGITAL-PRODUCTIONS'],
        },
        {
            code: Codes['SEARCH_HOME'],
            slug: Slugs['SEARCH-HOME'],
        },
        {
            code: Codes['SUBCATEGORY_ADS'],
            slug: Slugs['PERFORMING-ARTS'],
        },
        {
            code: Codes['SUBCATEGORY_AJO'],
            slug: Slugs['ARTE-JOURNAL'],
        },
        {
            code: Codes['SUBCATEGORY_ART'],
            slug: Slugs['ARTS'],
        },
        {
            code: Codes['SUBCATEGORY_ATA'],
            slug: Slugs['FOOD'],
        },
        {
            code: Codes['SUBCATEGORY_AUV'],
            slug: Slugs['SWITCHING-VIEWPOINTS'],
        },
        {
            code: Codes['SUBCATEGORY_BAR'],
            slug: Slugs['BAROQUE'],
        },
        {
            code: Codes['SUBCATEGORY_CIV'],
            slug: Slugs['CIVILISATIONS'],
        },
        {
            code: Codes['SUBCATEGORY_CLA'],
            slug: Slugs['CLASSICAL'],
        },
        {
            code: Codes['SUBCATEGORY_DCY'],
            slug: Slugs['INSIGHTS'],
        },
        {
            code: Codes['SUBCATEGORY_ENB'],
            slug: Slugs['IN-BRIEF'],
        },
        {
            code: Codes['SUBCATEGORY_ENN'],
            slug: Slugs['ENVIRONMENT'],
        },
        {
            code: Codes['SUBCATEGORY_ENQ'],
            slug: Slugs['INVESTIGATION-AND-REPORTS'],
        },
        {
            code: Codes['SUBCATEGORY_EVA'],
            slug: Slugs['TRAVEL'],
        },
        {
            code: Codes['SUBCATEGORY_HIP'],
            slug: Slugs['HIP-HOP'],
        },
        {
            code: Codes['SUBCATEGORY_IDE'],
            slug: Slugs['IDEAS'],
        },
        {
            code: Codes['SUBCATEGORY_JAZ'],
            slug: Slugs['JAZZ'],
        },
        {
            code: Codes['SUBCATEGORY_JUN'],
            slug: Slugs['KIDS'],
        },
        {
            code: Codes['SUBCATEGORY_KUL'],
            slug: Slugs['THROUGH-THE-EYES-OF-ARTISTS'],
        },
        {
            code: Codes['SUBCATEGORY_LGP'],
            slug: Slugs['FAMOUS-HISTORICAL-FIGURES'],
        },
        {
            code: Codes['SUBCATEGORY_MET'],
            slug: Slugs['METAL'],
        },
        {
            code: Codes['SUBCATEGORY_MUA'],
            slug: Slugs['POP-ROCK'],
        },
        {
            code: Codes['SUBCATEGORY_MUD'],
            slug: Slugs['WORLD'],
        },
        {
            code: Codes['SUBCATEGORY_MUE'],
            slug: Slugs['ELECTRONIC'],
        },
        {
            code: Codes['SUBCATEGORY_NEA'],
            slug: Slugs['PLANET-EARTH'],
        },
        {
            code: Codes['SUBCATEGORY_OPE'],
            slug: Slugs['OPERA'],
        },
        {
            code: Codes['SUBCATEGORY_POP'],
            slug: Slugs['POP-CULTURE'],
        },
        {
            code: Codes['SUBCATEGORY_SAN'],
            slug: Slugs['MEDICINE-AND-HEALTH'],
        },
        {
            code: Codes['SUBCATEGORY_TEC'],
            slug: Slugs['TECHNOLOGY-AND-INNOVATION'],
        },
        {
            code: Codes['SUBCATEGORY_VIA'],
            slug: Slugs['LIVES-FROM-AFAR'],
        },
        {
            code: Codes['SUBCATEGORY_XXE'],
            slug: Slugs['20TH-CENTURY'],
        },
    ],
    it: [
        {
            code: Codes['ARTE_CONCERT'],
            slug: Slugs['ARTE-CONCERT_ARTE_CONCERT'],
        },
        {
            code: Codes['AVN'],
            slug: Slugs['PROSSIMAMENTE'],
        },
        {
            code: Codes['CATEGORY_ACT'],
            slug: Slugs['POLITICA-E-SOCIETA'],
        },
        {
            code: Codes['CATEGORY_CIN'],
            slug: Slugs['CINEMA'],
        },
        {
            code: Codes['CATEGORY_CPO'],
            slug: Slugs['CULTURA'],
        },
        {
            code: Codes['CATEGORY_DEC'],
            slug: Slugs['VIAGGI-E-SCOPERTE'],
        },
        {
            code: Codes['CATEGORY_HIS'],
            slug: Slugs['STORIA'],
        },
        {
            code: Codes['CATEGORY_SCI'],
            slug: Slugs['SCIENZE'],
        },
        {
            code: Codes['CATEGORY_SER'],
            slug: Slugs['SERIE-E-FICTION'],
        },
        {
            code: Codes['CLUB_HOME'],
            slug: Slugs['CLUB-HOME'],
        },
        {
            code: Codes['COLLECTION'],
            slug: Slugs['COLLECTION-PAGE-COLLECTIONID_RC-016485'],
        },
        {
            code: Codes['DOR'],
            slug: Slugs['DOCUMENTARI-E-REPORTAGE'],
        },
        {
            code: Codes['EMI'],
            slug: Slugs['PROGRAMMI'],
        },
        {
            code: Codes['LAST_CHANCE'],
            slug: Slugs['ULTIMA-OPPORTUNITA'],
        },
        {
            code: Codes['MAGAZINES'],
            slug: Slugs['PROGRAMMES'],
        },
        {
            code: Codes['MOST_RECENT'],
            slug: Slugs['PIU-RECENTI'],
        },
        {
            code: Codes['MOST_VIEWED'],
            slug: Slugs['PIU-VISTI'],
        },
        {
            code: Codes['MY_ADVANTAGE'],
            slug: Slugs['VANTAGGI'],
        },
        {
            code: Codes['PRODNUM'],
            slug: Slugs['PRODUZIONI-DIGITALI'],
        },
        {
            code: Codes['SEARCH_HOME'],
            slug: Slugs['RICERCA-HOME'],
        },
        {
            code: Codes['SUBCATEGORY_ADS'],
            slug: Slugs['ARTI-DELLO-SPETTACOLO'],
        },
        {
            code: Codes['SUBCATEGORY_AJO'],
            slug: Slugs['ARTE-JOURNAL'],
        },
        {
            code: Codes['SUBCATEGORY_ART'],
            slug: Slugs['ARTE'],
        },
        {
            code: Codes['SUBCATEGORY_ATA'],
            slug: Slugs['FOOD'],
        },
        {
            code: Codes['SUBCATEGORY_AUV'],
            slug: Slugs['PUNTI-DI-VISTA'],
        },
        {
            code: Codes['SUBCATEGORY_BAR'],
            slug: Slugs['MUSICA-BAROCCA'],
        },
        {
            code: Codes['SUBCATEGORY_CIV'],
            slug: Slugs['CIVILTA'],
        },
        {
            code: Codes['SUBCATEGORY_CLA'],
            slug: Slugs['MUSICA-CLASSICA'],
        },
        {
            code: Codes['SUBCATEGORY_DCY'],
            slug: Slugs['ANALISI'],
        },
        {
            code: Codes['SUBCATEGORY_ENB'],
            slug: Slugs['IN-PILLOLE'],
        },
        {
            code: Codes['SUBCATEGORY_ENN'],
            slug: Slugs['AMBIENTE-E-NATURA'],
        },
        {
            code: Codes['SUBCATEGORY_ENQ'],
            slug: Slugs['INCHIESTE-E-REPORTAGE'],
        },
        {
            code: Codes['SUBCATEGORY_EVA'],
            slug: Slugs['VIAGGI'],
        },
        {
            code: Codes['SUBCATEGORY_HIP'],
            slug: Slugs['HIP-HOP'],
        },
        {
            code: Codes['SUBCATEGORY_IDE'],
            slug: Slugs['IDEE'],
        },
        {
            code: Codes['SUBCATEGORY_JAZ'],
            slug: Slugs['JAZZ'],
        },
        {
            code: Codes['SUBCATEGORY_JUN'],
            slug: Slugs['JUNIOR'],
        },
        {
            code: Codes['SUBCATEGORY_KUL'],
            slug: Slugs['ATTUALITA-CULTURALE'],
        },
        {
            code: Codes['SUBCATEGORY_LGP'],
            slug: Slugs['I-GRANDI-PERSONAGGI'],
        },
        {
            code: Codes['SUBCATEGORY_MET'],
            slug: Slugs['METAL'],
        },
        {
            code: Codes['SUBCATEGORY_MUA'],
            slug: Slugs['POP-ROCK'],
        },
        {
            code: Codes['SUBCATEGORY_MUD'],
            slug: Slugs['MUSICHE-DEL-MONDO'],
        },
        {
            code: Codes['SUBCATEGORY_MUE'],
            slug: Slugs['ELETTRONICA'],
        },
        {
            code: Codes['SUBCATEGORY_NEA'],
            slug: Slugs['NATURA-E-ANIMALI'],
        },
        {
            code: Codes['SUBCATEGORY_OPE'],
            slug: Slugs['OPERA'],
        },
        {
            code: Codes['SUBCATEGORY_POP'],
            slug: Slugs['CULTURA-POP'],
        },
        {
            code: Codes['SUBCATEGORY_SAN'],
            slug: Slugs['MEDICINA-E-SALUTE'],
        },
        {
            code: Codes['SUBCATEGORY_TEC'],
            slug: Slugs['TECNOLOGIA-E-INNOVAZIONE'],
        },
        {
            code: Codes['SUBCATEGORY_VIA'],
            slug: Slugs['VITE-LONTANE'],
        },
        {
            code: Codes['SUBCATEGORY_XXE'],
            slug: Slugs['XX-SECOLO'],
        },
    ],
    pl: [
        {
            code: Codes['ARTE_CONCERT'],
            slug: Slugs['ARTE-CONCERT'],
        },
        {
            code: Codes['AVN'],
            slug: Slugs['WKROTCE-DOSTEPNE'],
        },
        {
            code: Codes['CATEGORY_ACT'],
            slug: Slugs['POLITYKA-I-SPOLECZENSTWO'],
        },
        {
            code: Codes['CATEGORY_CIN'],
            slug: Slugs['KINO'],
        },
        {
            code: Codes['CATEGORY_CPO'],
            slug: Slugs['KULTURA'],
        },
        {
            code: Codes['CATEGORY_DEC'],
            slug: Slugs['ODKRYCIA'],
        },
        {
            code: Codes['CATEGORY_HIS'],
            slug: Slugs['HISTORIA'],
        },
        {
            code: Codes['CATEGORY_SCI'],
            slug: Slugs['NAUKA'],
        },
        {
            code: Codes['CATEGORY_SER'],
            slug: Slugs['SERIALE-I-FILMY-FABULARNE'],
        },
        {
            code: Codes['CLUB_HOME'],
            slug: Slugs['CLUB-HOME'],
        },
        {
            code: Codes['COLLECTION'],
            slug: Slugs['COLLECTION-PAGE-COLLECTIONID_RC-016485'],
        },
        {
            code: Codes['DOR'],
            slug: Slugs['DOKUMENTY-I-REPORTAZE'],
        },
        {
            code: Codes['EMI'],
            slug: Slugs['MAGAZYNY'],
        },
        {
            code: Codes['LAST_CHANCE'],
            slug: Slugs['OSTATNIA-SZANSA'],
        },
        {
            code: Codes['MAGAZINES'],
            slug: Slugs['PROGRAMY'],
        },
        {
            code: Codes['MOST_RECENT'],
            slug: Slugs['NAJNOWSZE'],
        },
        {
            code: Codes['MOST_VIEWED'],
            slug: Slugs['NAJCZESCIEJ-OGLADANE'],
        },
        {
            code: Codes['MY_ADVANTAGE'],
            slug: Slugs['KORZYSCI'],
        },
        {
            code: Codes['PRODNUM'],
            slug: Slugs['PRODUKCJE-CYFROWE'],
        },
        {
            code: Codes['SEARCH_HOME'],
            slug: Slugs['WYSZUKIWANIE-HOME'],
        },
        {
            code: Codes['SUBCATEGORY_ADS'],
            slug: Slugs['WYSTEPY-SCENICZNE'],
        },
        {
            code: Codes['SUBCATEGORY_AJO'],
            slug: Slugs['ARTE-JOURNAL'],
        },
        {
            code: Codes['SUBCATEGORY_ART'],
            slug: Slugs['SZTUKA'],
        },
        {
            code: Codes['SUBCATEGORY_ATA'],
            slug: Slugs['PODANO-DO-STOLU'],
        },
        {
            code: Codes['SUBCATEGORY_AUV'],
            slug: Slugs['ZMIANA-PERSPEKTYWY'],
        },
        {
            code: Codes['SUBCATEGORY_BAR'],
            slug: Slugs['MUZYKA-BAROKOWA'],
        },
        {
            code: Codes['SUBCATEGORY_CIV'],
            slug: Slugs['CYWILIZACJE'],
        },
        {
            code: Codes['SUBCATEGORY_CLA'],
            slug: Slugs['MUZYKA-KLASYCZNA'],
        },
        {
            code: Codes['SUBCATEGORY_DCY'],
            slug: Slugs['ANALIZY'],
        },
        {
            code: Codes['SUBCATEGORY_ENB'],
            slug: Slugs['W-SKROCIE'],
        },
        {
            code: Codes['SUBCATEGORY_ENN'],
            slug: Slugs['NATURA-I-SRODOWISKO'],
        },
        {
            code: Codes['SUBCATEGORY_ENQ'],
            slug: Slugs['SERIALE-SLEDCZE-I-REPORTAZE'],
        },
        {
            code: Codes['SUBCATEGORY_EVA'],
            slug: Slugs['PODROZE'],
        },
        {
            code: Codes['SUBCATEGORY_HIP'],
            slug: Slugs['HIP-HOP'],
        },
        {
            code: Codes['SUBCATEGORY_IDE'],
            slug: Slugs['IDEE'],
        },
        {
            code: Codes['SUBCATEGORY_JAZ'],
            slug: Slugs['JAZZ'],
        },
        {
            code: Codes['SUBCATEGORY_JUN'],
            slug: Slugs['DLA-MLODZIEZY'],
        },
        {
            code: Codes['SUBCATEGORY_KUL'],
            slug: Slugs['OCZAMI-ARTYSTOW'],
        },
        {
            code: Codes['SUBCATEGORY_LGP'],
            slug: Slugs['WYBITNE-POSTACIE'],
        },
        {
            code: Codes['SUBCATEGORY_MET'],
            slug: Slugs['METAL'],
        },
        {
            code: Codes['SUBCATEGORY_MUA'],
            slug: Slugs['POP-I-ROCK'],
        },
        {
            code: Codes['SUBCATEGORY_MUD'],
            slug: Slugs['MUZYKA-Z-CALEGO-SWIATA'],
        },
        {
            code: Codes['SUBCATEGORY_MUE'],
            slug: Slugs['MUZYKA-ELEKTRONICZNA'],
        },
        {
            code: Codes['SUBCATEGORY_NEA'],
            slug: Slugs['PRZYRODA-I-ZWIERZETA'],
        },
        {
            code: Codes['SUBCATEGORY_OPE'],
            slug: Slugs['OPERA'],
        },
        {
            code: Codes['SUBCATEGORY_POP'],
            slug: Slugs['POPKULTURA'],
        },
        {
            code: Codes['SUBCATEGORY_SAN'],
            slug: Slugs['MEDYCYNA-I-ZDROWIE'],
        },
        {
            code: Codes['SUBCATEGORY_TEC'],
            slug: Slugs['TECHNOLOGIE-I-INNOWACJE'],
        },
        {
            code: Codes['SUBCATEGORY_VIA'],
            slug: Slugs['JAK-ZYJA-INNI'],
        },
        {
            code: Codes['SUBCATEGORY_XXE'],
            slug: Slugs['XX-WIEK'],
        },
    ],
    de: [
        {
            code: Codes['ACCESSIBLE_PROGRAMS'],
            slug: Slugs['BARRIEREFREIE-PROGRAMME'],
        },
        {
            code: Codes['ARTE_CONCERT'],
            slug: Slugs['CONCERT-KONZERTE-LIVE-UND-IM-REPLAY'],
        },
        {
            code: Codes['AUDIO_DESCRIPTION'],
            slug: Slugs['PROGRAMME-MIT-AUDIODESKRIPTION'],
        },
        {
            code: Codes['AVN'],
            slug: Slugs['DEMNAECHST'],
        },
        {
            code: Codes['CATEGORY_ACT'],
            slug: Slugs['AKTUELLES-UND-GESELLSCHAFT'],
        },
        {
            code: Codes['CATEGORY_CIN'],
            slug: Slugs['KINO'],
        },
        {
            code: Codes['CATEGORY_CPO'],
            slug: Slugs['KULTUR-UND-POP'],
        },
        {
            code: Codes['CATEGORY_DEC'],
            slug: Slugs['ENTDECKUNG-DER-WELT'],
        },
        {
            code: Codes['CATEGORY_HIS'],
            slug: Slugs['GESCHICHTE'],
        },
        {
            code: Codes['CATEGORY_SCI'],
            slug: Slugs['WISSENSCHAFT'],
        },
        {
            code: Codes['CATEGORY_SER'],
            slug: Slugs['FERNSEHFILME-UND-SERIEN'],
        },
        {
            code: Codes['CLOSED_CAPTIONING'],
            slug: Slugs['PROGRAMME-MIT-UNTERTITELN'],
        },
        {
            code: Codes['CLUB_HOME'],
            slug: Slugs['CLUB-HOME'],
        },
        {
            code: Codes['COLLECTION'],
            slug: Slugs['COLLECTION-PAGE-COLLECTIONID_RC-016485'],
        },
        {
            code: Codes['COLLECTORS'],
            slug: Slugs['30-JAHRE'],
        },
        {
            code: Codes['CONCERT_GUIDE'],
            slug: Slugs['DEMNACHST'],
        },
        {
            code: Codes['DOR'],
            slug: Slugs['DOKUMENTATIONEN-UND-REPORTAGEN'],
        },
        {
            code: Codes['EDITORIAL_LAST_CHANCE'],
            slug: Slugs['BALD-OFFLINE'],
        },
        {
            code: Codes['EMI'],
            slug: Slugs['SENDUNGEN'],
        },
        {
            code: Codes['FESTIVAL_CANNES'],
            slug: Slugs['FILMFESTIVAL-CANNES'],
        },
        {
            code: Codes['HES'],
            slug: Slugs['HELDINNEN-IN-SERIE'],
        },
        {
            code: Codes['LAST_CHANCE'],
            slug: Slugs['LETZTE-CHANCE'],
        },
        {
            code: Codes['LIVE'],
            slug: Slugs['LIVE-TV-DIE-PROGRAMME-VON-ARTE-IM-LIVESTREAM'],
        },
        {
            code: Codes['MAGAZINES'],
            slug: Slugs['SENDUNGEN'],
        },
        {
            code: Codes['MOST_RECENT'],
            slug: Slugs['NEUESTE-VIDEOS'],
        },
        {
            code: Codes['MOST_VIEWED'],
            slug: Slugs['MEISTGESEHEN'],
        },
        {
            code: Codes['MY_ADVANTAGE'],
            slug: Slugs['VORTEILE'],
        },
        {
            code: Codes['PRODNUM'],
            slug: Slugs['DIGITALE-PRODUKTIONEN'],
        },
        {
            code: Codes['SEARCH_HOME'],
            slug: Slugs['SUCHE'],
        },
        {
            code: Codes['SER_NORTH'],
            slug: Slugs['SKANDINAVISCHE-SERIEN'],
        },
        {
            code: Codes['SUBCATEGORY_ACC'],
            slug: Slugs['RUND-UM-DEN-FILM'],
        },
        {
            code: Codes['SUBCATEGORY_ADS'],
            slug: Slugs['BUEHNEN-PERFORMANCE'],
        },
        {
            code: Codes['SUBCATEGORY_AJO'],
            slug: Slugs['AKTUELLES'],
        },
        {
            code: Codes['SUBCATEGORY_ART'],
            slug: Slugs['KUNST'],
        },
        {
            code: Codes['SUBCATEGORY_ATA'],
            slug: Slugs['KULINARIK'],
        },
        {
            code: Codes['SUBCATEGORY_AUV'],
            slug: Slugs['PERSPEKTIVWECHSEL'],
        },
        {
            code: Codes['SUBCATEGORY_BAR'],
            slug: Slugs['BAROCKMUSIK'],
        },
        {
            code: Codes['SUBCATEGORY_CHU'],
            slug: Slugs['KURZ-UND-WITZIG'],
        },
        {
            code: Codes['SUBCATEGORY_CIV'],
            slug: Slugs['DIE-ZEIT-VOR-DEM-20-JAHRHUNDERT'],
        },
        {
            code: Codes['SUBCATEGORY_CLA'],
            slug: Slugs['KLASSIK'],
        },
        {
            code: Codes['SUBCATEGORY_CMG'],
            slug: Slugs['KURZFILME'],
        },
        {
            code: Codes['SUBCATEGORY_CMU'],
            slug: Slugs['STUMMFILME'],
        },
        {
            code: Codes['SUBCATEGORY_DCY'],
            slug: Slugs['HINTERGRUND'],
        },
        {
            code: Codes['SUBCATEGORY_ENB'],
            slug: Slugs['WISSEN-KOMPAKT'],
        },
        {
            code: Codes['SUBCATEGORY_ENN'],
            slug: Slugs['UMWELT-UND-NATUR'],
        },
        {
            code: Codes['SUBCATEGORY_ENQ'],
            slug: Slugs['REPORTAGEN-UND-RECHERCHEN'],
        },
        {
            code: Codes['SUBCATEGORY_EVA'],
            slug: Slugs['REISEN'],
        },
        {
            code: Codes['SUBCATEGORY_FIC'],
            slug: Slugs['FERNSEHFILME'],
        },
        {
            code: Codes['SUBCATEGORY_FLM'],
            slug: Slugs['FILME'],
        },
        {
            code: Codes['SUBCATEGORY_HIP'],
            slug: Slugs['HIP-HOP'],
        },
        {
            code: Codes['SUBCATEGORY_IDE'],
            slug: Slugs['IDEENWELTEN'],
        },
        {
            code: Codes['SUBCATEGORY_JAZ'],
            slug: Slugs['JAZZ'],
        },
        {
            code: Codes['SUBCATEGORY_JUN'],
            slug: Slugs['JUNIOR'],
        },
        {
            code: Codes['SUBCATEGORY_KUL'],
            slug: Slugs['KULTUR-NEWS'],
        },
        {
            code: Codes['SUBCATEGORY_LGP'],
            slug: Slugs['BIOGRAPHIEN'],
        },
        {
            code: Codes['SUBCATEGORY_MCL'],
            slug: Slugs['FILMGROESSEN'],
        },
        {
            code: Codes['SUBCATEGORY_MET'],
            slug: Slugs['METAL'],
        },
        {
            code: Codes['SUBCATEGORY_MUA'],
            slug: Slugs['POP-ROCK'],
        },
        {
            code: Codes['SUBCATEGORY_MUD'],
            slug: Slugs['WORLD'],
        },
        {
            code: Codes['SUBCATEGORY_MUE'],
            slug: Slugs['ELECTRONIC'],
        },
        {
            code: Codes['SUBCATEGORY_NEA'],
            slug: Slugs['NATUR-UND-TIERE'],
        },
        {
            code: Codes['SUBCATEGORY_OPE'],
            slug: Slugs['OPER'],
        },
        {
            code: Codes['SUBCATEGORY_POP'],
            slug: Slugs['POPKULTUR'],
        },
        {
            code: Codes['SUBCATEGORY_SAN'],
            slug: Slugs['GESUNDHEIT-UND-MEDIZIN'],
        },
        {
            code: Codes['SUBCATEGORY_SES'],
            slug: Slugs['SERIEN'],
        },
        {
            code: Codes['SUBCATEGORY_TEC'],
            slug: Slugs['TECHNIK-UND-INNOVATION'],
        },
        {
            code: Codes['SUBCATEGORY_VIA'],
            slug: Slugs['LEBEN-ANDERSWO'],
        },
        {
            code: Codes['SUBCATEGORY_WEB'],
            slug: Slugs['WEBSERIEN'],
        },
        {
            code: Codes['SUBCATEGORY_XXE'],
            slug: Slugs['DAS-20-JAHRHUNDERT'],
        },
        {
            code: Codes['TV_GUIDE'],
            slug: Slugs['GUIDE'],
        },
    ],
    fr: [
        {
            code: Codes['ACCESSIBLE_PROGRAMS'],
            slug: Slugs['VERSIONS-ACCESSIBLES-DES-PROGRAMMES'],
        },
        {
            code: Codes['ARTE_CONCERT'],
            slug: Slugs['CONCERT-PLUS-DE-600-CONCERTS-EN-STREAMING-ET-EN-ACCES-LIBRE'],
        },
        {
            code: Codes['AUDIO_DESCRIPTION'],
            slug: Slugs['PROGRAMMES-EN-AUDIODESCRIPTION'],
        },
        {
            code: Codes['AVN'],
            slug: Slugs['A-VENIR'],
        },
        {
            code: Codes['CATEGORY_ACT'],
            slug: Slugs['INFO-ET-SOCIETE'],
        },
        {
            code: Codes['CATEGORY_CIN'],
            slug: Slugs['CINEMA'],
        },
        {
            code: Codes['CATEGORY_CPO'],
            slug: Slugs['CULTURE-ET-POP'],
        },
        {
            code: Codes['CATEGORY_DEC'],
            slug: Slugs['VOYAGES-ET-DECOUVERTES'],
        },
        {
            code: Codes['CATEGORY_HIS'],
            slug: Slugs['HISTOIRE'],
        },
        {
            code: Codes['CATEGORY_SCI'],
            slug: Slugs['SCIENCES'],
        },
        {
            code: Codes['CATEGORY_SER'],
            slug: Slugs['SERIES-ET-FICTIONS'],
        },
        {
            code: Codes['CLOSED_CAPTIONING'],
            slug: Slugs['PROGRAMMES-EN-SOUS-TITRAGE-SOURD-ET-MALENTENDANT'],
        },
        {
            code: Codes['CLUB_HOME'],
            slug: Slugs['CLUB-HOME'],
        },
        {
            code: Codes['COLLECTION'],
            slug: Slugs['COLLECTION-PAGE-COLLECTIONID_RC-016485'],
        },
        {
            code: Codes['CONCERT_GUIDE'],
            slug: Slugs['AGENDA'],
        },
        {
            code: Codes['DOR'],
            slug: Slugs['DOCUMENTAIRES-ET-REPORTAGES'],
        },
        {
            code: Codes['EDITORIAL_LAST_CHANCE'],
            slug: Slugs['LES-DERNIERES-CHANCES'],
        },
        {
            code: Codes['EMI'],
            slug: Slugs['EMISSIONS'],
        },
        {
            code: Codes['HES'],
            slug: Slugs['HEROINES-EN-SERIE'],
        },
        {
            code: Codes['LAST_CHANCE'],
            slug: Slugs['DERNIERE-CHANCE'],
        },
        {
            code: Codes['LIVE'],
            slug: Slugs['TV-EN-DIRECT-REGARDER-ARTE-EN-DIRECT-SUR-INTERNET'],
        },
        {
            code: Codes['MAGAZINES'],
            slug: Slugs['EMISSIONS'],
        },
        {
            code: Codes['MOST_RECENT'],
            slug: Slugs['PLUS-RECENTES'],
        },
        {
            code: Codes['MOST_VIEWED'],
            slug: Slugs['PLUS-VUES'],
        },
        {
            code: Codes['MY_ADVANTAGE'],
            slug: Slugs['MES-AVANTAGES'],
        },
        {
            code: Codes['PRODNUM'],
            slug: Slugs['PRODUCTIONS-NUMERIQUES'],
        },
        {
            code: Codes['SEARCH_HOME'],
            slug: Slugs['RECHERCHE'],
        },
        {
            code: Codes['SER_NORTH'],
            slug: Slugs['SERIES-NORDIQUES'],
        },
        {
            code: Codes['SUBCATEGORY_ACC'],
            slug: Slugs['ACTUALITE-DU-CINEMA'],
        },
        {
            code: Codes['SUBCATEGORY_ADS'],
            slug: Slugs['ARTS-DE-LA-SCENE'],
        },
        {
            code: Codes['SUBCATEGORY_AJO'],
            slug: Slugs['A-LA-UNE'],
        },
        {
            code: Codes['SUBCATEGORY_ART'],
            slug: Slugs['ARTS'],
        },
        {
            code: Codes['SUBCATEGORY_ATA'],
            slug: Slugs['A-TABLE'],
        },
        {
            code: Codes['SUBCATEGORY_AUV'],
            slug: Slugs['AUTREMENT-VU'],
        },
        {
            code: Codes['SUBCATEGORY_BAR'],
            slug: Slugs['MUSIQUE-BAROQUE'],
        },
        {
            code: Codes['SUBCATEGORY_CHU'],
            slug: Slugs['COURTS-HUMORISTIQUES'],
        },
        {
            code: Codes['SUBCATEGORY_CIV'],
            slug: Slugs['CIVILISATIONS'],
        },
        {
            code: Codes['SUBCATEGORY_CLA'],
            slug: Slugs['CLASSIQUE'],
        },
        {
            code: Codes['SUBCATEGORY_CMG'],
            slug: Slugs['COURTS-METRAGES'],
        },
        {
            code: Codes['SUBCATEGORY_CMU'],
            slug: Slugs['FILMS-MUETS'],
        },
        {
            code: Codes['SUBCATEGORY_DCY'],
            slug: Slugs['DECRYPTAGES'],
        },
        {
            code: Codes['SUBCATEGORY_ENB'],
            slug: Slugs['EN-BREF'],
        },
        {
            code: Codes['SUBCATEGORY_ENN'],
            slug: Slugs['ENVIRONNEMENT-ET-NATURE'],
        },
        {
            code: Codes['SUBCATEGORY_ENQ'],
            slug: Slugs['ENQUETES-ET-REPORTAGES'],
        },
        {
            code: Codes['SUBCATEGORY_EVA'],
            slug: Slugs['EVASION'],
        },
        {
            code: Codes['SUBCATEGORY_FIC'],
            slug: Slugs['FICTIONS'],
        },
        {
            code: Codes['SUBCATEGORY_FLM'],
            slug: Slugs['FILMS'],
        },
        {
            code: Codes['SUBCATEGORY_HIP'],
            slug: Slugs['HIP-HOP'],
        },
        {
            code: Codes['SUBCATEGORY_IDE'],
            slug: Slugs['IDEES'],
        },
        {
            code: Codes['SUBCATEGORY_JAZ'],
            slug: Slugs['JAZZ'],
        },
        {
            code: Codes['SUBCATEGORY_JUN'],
            slug: Slugs['JUNIOR'],
        },
        {
            code: Codes['SUBCATEGORY_KUL'],
            slug: Slugs['ACTUALITES-CULTURELLES'],
        },
        {
            code: Codes['SUBCATEGORY_LGP'],
            slug: Slugs['LES-GRANDS-PERSONNAGES'],
        },
        {
            code: Codes['SUBCATEGORY_MCL'],
            slug: Slugs['LES-GRANDS-DU-7E-ART'],
        },
        {
            code: Codes['SUBCATEGORY_MET'],
            slug: Slugs['METAL'],
        },
        {
            code: Codes['SUBCATEGORY_MUA'],
            slug: Slugs['POP-ROCK'],
        },
        {
            code: Codes['SUBCATEGORY_MUD'],
            slug: Slugs['WORLD'],
        },
        {
            code: Codes['SUBCATEGORY_MUE'],
            slug: Slugs['MUSIQUES-ELECTRONIQUES'],
        },
        {
            code: Codes['SUBCATEGORY_NEA'],
            slug: Slugs['NATURE-ET-ANIMAUX'],
        },
        {
            code: Codes['SUBCATEGORY_OPE'],
            slug: Slugs['OPERA'],
        },
        {
            code: Codes['SUBCATEGORY_POP'],
            slug: Slugs['CULTURE-POP'],
        },
        {
            code: Codes['SUBCATEGORY_SAN'],
            slug: Slugs['MEDECINE-ET-SANTE'],
        },
        {
            code: Codes['SUBCATEGORY_SES'],
            slug: Slugs['SERIES'],
        },
        {
            code: Codes['SUBCATEGORY_TEC'],
            slug: Slugs['TECHNOLOGIES-ET-INNOVATIONS'],
        },
        {
            code: Codes['SUBCATEGORY_VIA'],
            slug: Slugs['VIES-D-AILLEURS'],
        },
        {
            code: Codes['SUBCATEGORY_WEB'],
            slug: Slugs['WEBSERIES'],
        },
        {
            code: Codes['SUBCATEGORY_XXE'],
            slug: Slugs['XXE-SIECLE'],
        },
        {
            code: Codes['TV_GUIDE'],
            slug: Slugs['GUIDE'],
        },
    ],
    es: [
        {
            code: Codes['ARTE_CONCERT'],
            slug: Slugs['ARTE-CONCERT'],
        },
        {
            code: Codes['AVN'],
            slug: Slugs['PRONTO-DISPONIBLE'],
        },
        {
            code: Codes['CATEGORY_ACT'],
            slug: Slugs['POLITICA-Y-SOCIEDAD'],
        },
        {
            code: Codes['CATEGORY_CIN'],
            slug: Slugs['CINE'],
        },
        {
            code: Codes['CATEGORY_CPO'],
            slug: Slugs['CULTURA-Y-POP'],
        },
        {
            code: Codes['CATEGORY_DEC'],
            slug: Slugs['VIAJES-Y-NATURALEZA'],
        },
        {
            code: Codes['CATEGORY_HIS'],
            slug: Slugs['HISTORIA'],
        },
        {
            code: Codes['CATEGORY_SCI'],
            slug: Slugs['CIENCIAS'],
        },
        {
            code: Codes['CATEGORY_SER'],
            slug: Slugs['SERIES-Y-FICCIONES'],
        },
        {
            code: Codes['CLUB_HOME'],
            slug: Slugs['CLUB-HOME'],
        },
        {
            code: Codes['COLLECTION'],
            slug: Slugs['COLLECTION-PAGE-COLLECTIONID_RC-016485'],
        },
        {
            code: Codes['DOR'],
            slug: Slugs['DOCUMENTALES-Y-REPORTAJES'],
        },
        {
            code: Codes['EMI'],
            slug: Slugs['EMISIONES'],
        },
        {
            code: Codes['LAST_CHANCE'],
            slug: Slugs['ULTIMA-OPORTUNIDAD'],
        },
        {
            code: Codes['MAGAZINES'],
            slug: Slugs['EMISIONES'],
        },
        {
            code: Codes['MOST_RECENT'],
            slug: Slugs['LOS-MAS-RECIENTES'],
        },
        {
            code: Codes['MOST_VIEWED'],
            slug: Slugs['MAS-VISTOS'],
        },
        {
            code: Codes['MY_ADVANTAGE'],
            slug: Slugs['MIS-VENTAJAS'],
        },
        {
            code: Codes['PRODNUM'],
            slug: Slugs['PRODUCCIONES-DIGITALES'],
        },
        {
            code: Codes['SEARCH_HOME'],
            slug: Slugs['BUSQUEDA-HOME'],
        },
        {
            code: Codes['SUBCATEGORY_ADS'],
            slug: Slugs['ARTES-ESCENICAS'],
        },
        {
            code: Codes['SUBCATEGORY_AJO'],
            slug: Slugs['ARTE-JOURNAL'],
        },
        {
            code: Codes['SUBCATEGORY_ART'],
            slug: Slugs['ARTES'],
        },
        {
            code: Codes['SUBCATEGORY_ATA'],
            slug: Slugs['A-LA-MESA'],
        },
        {
            code: Codes['SUBCATEGORY_AUV'],
            slug: Slugs['CON-OTROS-OJOS'],
        },
        {
            code: Codes['SUBCATEGORY_BAR'],
            slug: Slugs['MUSICA-BARROCA'],
        },
        {
            code: Codes['SUBCATEGORY_CIV'],
            slug: Slugs['CIVILIZACIONES'],
        },
        {
            code: Codes['SUBCATEGORY_CLA'],
            slug: Slugs['CLASICA'],
        },
        {
            code: Codes['SUBCATEGORY_DCY'],
            slug: Slugs['ANALISIS'],
        },
        {
            code: Codes['SUBCATEGORY_ENB'],
            slug: Slugs['AL-GRANO'],
        },
        {
            code: Codes['SUBCATEGORY_ENN'],
            slug: Slugs['MEDIO-AMBIENTE-Y-NATURALEZA'],
        },
        {
            code: Codes['SUBCATEGORY_ENQ'],
            slug: Slugs['INVESTIGACIONES-Y-REPORTAJES'],
        },
        {
            code: Codes['SUBCATEGORY_EVA'],
            slug: Slugs['VIAJES'],
        },
        {
            code: Codes['SUBCATEGORY_HIP'],
            slug: Slugs['HIP-HOP'],
        },
        {
            code: Codes['SUBCATEGORY_IDE'],
            slug: Slugs['IDEAS'],
        },
        {
            code: Codes['SUBCATEGORY_JAZ'],
            slug: Slugs['JAZZ'],
        },
        {
            code: Codes['SUBCATEGORY_JUN'],
            slug: Slugs['JOVEN'],
        },
        {
            code: Codes['SUBCATEGORY_KUL'],
            slug: Slugs['LOS-OJOS-DEL-ARTE'],
        },
        {
            code: Codes['SUBCATEGORY_LGP'],
            slug: Slugs['GRANDES-PERSONAJES'],
        },
        {
            code: Codes['SUBCATEGORY_MET'],
            slug: Slugs['METAL'],
        },
        {
            code: Codes['SUBCATEGORY_MUA'],
            slug: Slugs['POP-Y-ROCK'],
        },
        {
            code: Codes['SUBCATEGORY_MUD'],
            slug: Slugs['MUSICAS-DEL-MUNDO'],
        },
        {
            code: Codes['SUBCATEGORY_MUE'],
            slug: Slugs['MUSICA-ELECTRONICA'],
        },
        {
            code: Codes['SUBCATEGORY_NEA'],
            slug: Slugs['NATURALEZA-Y-ANIMALES'],
        },
        {
            code: Codes['SUBCATEGORY_OPE'],
            slug: Slugs['OPERA'],
        },
        {
            code: Codes['SUBCATEGORY_POP'],
            slug: Slugs['CULTURA-POP'],
        },
        {
            code: Codes['SUBCATEGORY_SAN'],
            slug: Slugs['MEDICINA-Y-SALUD'],
        },
        {
            code: Codes['SUBCATEGORY_TEC'],
            slug: Slugs['TECNOLOGIAS-E-INNOVACION'],
        },
        {
            code: Codes['SUBCATEGORY_VIA'],
            slug: Slugs['OTROS-LUGARES-OTRAS-VIDAS'],
        },
        {
            code: Codes['SUBCATEGORY_XXE'],
            slug: Slugs['EL-SIGLO-XX'],
        },
    ],
};
